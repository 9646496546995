import { API_BASE, API_ENDPOINT_PORTAL_ZONES, API_ENDPOINT_PORTAL_ZONES_BILLABLES, API_ENDPOINT_PORTAL_ZONE } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { BillablePackage } from '@/constants/accounts/billables'
import { useAuth } from '@/composables/useAuth'
import { ref, computed } from 'vue'
import { useToast } from '@/composables/useToast'
import type { ZoneApiResponse, SetZoneParams, MappedZone } from '@/components/zones/types'

const ZONE_COLORS = [ '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF' ]
export const ZONE_STATUSES = [
	{ name: 'In Service', id: 'in_service' },
	{ name: 'Extended', id: 'extended' },
	{ name: 'Non-Public', id: 'nonpublic' },
	{ name: 'Reference', id: 'reference' },
	{ name: 'Remote', id: 'remote' }
]

const zones = ref<MappedZone[]>([])
const zoneBillables = ref<BillablePackage[]>([])
const loading = ref(true)
const zone = ref<MappedZone | null>()
const parentZoneOptions = computed(() => {
	const parentZones = zones.value?.map((zone) => {
		return {
			name: zone.name,
			id: zone.id
		}
	}) || []
	return [ { name: 'None', id: '0' }, ...parentZones ]
})

export const useZones = () => {
	const { bugsnagReport } = useErrorReporter()
	const { userData } = useAuth()
	const { addToast } = useToast()

	const getZones = async (refetch?: boolean) => {
		if (!userData.value) { return } // Don't fetch if user data is not available
		if (zones.value?.length && !refetch) { return } // Don't fetch if zones are already loaded
		loading.value = true

		try {
			await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_ZONES}`, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						const responseData: ZoneApiResponse[] = response._data
						zones.value = responseData?.map(zone => mapZone(zone)) as unknown as MappedZone[]
					} else {
						const responseError = response._data?.errors?.[0]
						throw new Error(`getZones: No data or failed with status ${response.status}${responseError ? ` - ${responseError}` : ''}`)
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getZones - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			loading.value = false
		}
	}

	const getZoneBillables = async (zoneId: string) => {
		loading.value = true
		if (!zoneId) {
			loading.value = false
			return
		}
		try {
			await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_ZONES_BILLABLES.replace('{zone-id}', zoneId)}`, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						const responseData: BillablePackage[] = response._data
						loading.value = false
						zoneBillables.value = responseData
					} else {
						const responseError = response._data?.errors?.[0]
						throw new Error(`getZoneBillables: No data or failed with status ${response.status}${responseError ? ` - ${responseError}` : ''}`)
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getZoneBillables - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			loading.value = false
		}
	}

	const mapZone = (zone: ZoneApiResponse) : MappedZone => {
		return {
			id: zone.id,
			name: zone.name,
			type: zone.type,
			isSubzone: zone.isSubzone.toString(),
			parentZoneId: zone.parentZoneId.toString(),
			points: zone.points || '',
			addressCount: zone.addressCount.toString(),
			customersCount: zone.customersCount.toString(),
			penetrationRate: zone.customersCount ? ((zone.customersCount / zone.addressCount) * 100).toFixed(2) : '0.00',
			leadsCount: zone.leadsCount.toString(),
			prospectCount: zone.prospectCount.toString(),
			color: ZONE_COLORS[Math.floor(Math.random() * ZONE_COLORS.length)],
			constructionEstimates: zone.constructionEstimates?.map(estimate => ({
				id: estimate.estimateId,
				title: estimate.title,
				text: estimate.text || ''
			})) || []
		}
	}

	const getZone = async (zoneId: string, resetIfNoId?: boolean) => {
		loading.value = true
		if (!zoneId) {
			if (resetIfNoId) {
				zone.value = null
			}
			loading.value = false
			return
		}
		try {
			await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_ZONE.replace('{zone-id}', zoneId)}`, {
				method: 'GET',
				credentials: 'include',
				onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						zone.value = mapZone(response._data)
					} else {
						const responseError = response._data?.errors?.[0]
						throw new Error(`getZone: No data or failed with status ${response.status}${responseError ? ` - ${responseError}` : ''}`)
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getZone - catch: ' + (e as Error).toString()),
				showToast: true
			})
		} finally {
			loading.value = false
		}
	}

	const setZone = async (params: SetZoneParams) => {
		loading.value = true
		const { zoneId, body } = params
		const isNewZone = !zoneId
		try {
			if (body.points && JSON.parse(body.points)) {
				body.points = JSON.parse(body.points)
			}
		} catch (e) {
			// console.log(e) TOOD: Handle Error
		}
		try {
			// TODO if body.points can't resolve to an object then it wont work (we need more checks around validating actual geoJson and messaging to the user)

			if (body.points && typeof body.points !== 'object') {
				delete body.points
			}
			await $fetch(isNewZone ? `${API_BASE}${API_ENDPOINT_PORTAL_ZONES}` : `${API_BASE}${API_ENDPOINT_PORTAL_ZONE.replace('{zone-id}', zoneId)}`, {
				method: isNewZone ? 'POST' : 'PUT',
				credentials: 'include',
				body,
				async onResponse ({ response }) {
					if (response.ok && response.status === 200) {
						addToast({
							title: `Zone ${isNewZone ? 'created' : 'updated'} successfully`,
							notificationType: 'success'
						})
						if (isNewZone) {
							getZones(true)
							await navigateTo('/zones')
						}
					} else {
						const responseError = response._data?.error || response._data?.errors?.[0]
						throw new Error(responseError || `setZone: Failed with status ${response.status}${responseError ? ` - ${responseError}` : ''}`)
					}
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('setZone - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: `Error ${isNewZone ? 'creating' : 'updating'} zone`,
					message: e instanceof Error ? e.message : `An error occurred while ${isNewZone ? 'creating' : 'updating'} the zone`
				}
			})
		} finally {
			loading.value = false
		}
	}

	return {
		ZONE_COLORS,
		ZONE_STATUSES,
		zones,
		zoneBillables,
		getZones,
		getZoneBillables,
		loading,
		getZone,
		zone,
		setZone,
		parentZoneOptions
	}
}
